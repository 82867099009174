import "../App.css";
import Zoom from 'react-reveal/Zoom';
import {imagenes} from '../config/configuraciones';
import { Link } from "react-router-dom";




function Footer() {
  return (
    <div className="footer">
      <br/>
      <div className="footer-logo">
      <Zoom bottom>
        <Link to="/">
        <img src="../../comodin2/logo.png" className="logo-footer" alt="Logotipo Club Comodín. El club de apuestas, máquinas slot tragamonedas y juegos de azar que más paga." />
        </Link>
        </Zoom>
      </div>
      <div className="footer-redes">
        <a href =" https://www.facebook.com/clubcomodinpy">
     
        <img
          src="../../comodin2/facebook-15.png"
          className="redes-sociales-f"
          alt="facebook"
        />
        </a>
        <a href =" https://www.instagram.com/clubcomodinpy">
        <img
          src="../../comodin2/instagram-15.png"
          className="redes-sociales-f"
          alt="instagram"
        />
        </a>
        <div className="separador">|</div>

        <div className="whatsapp-c">
     <a href="https://wa.link/p5149z">
        <img
          src="../../comodin2/whatsapp-15.png"
          className="redes-sociales-f-w"
          alt="whatsapp"
        />
        </a>
        <p className="a-c">Atención al cliente</p>
        </div>
      </div>
      <div className="footer-derechos">
      <p>Club Comodín-Todos los derechos reservados</p>
      </div>
    </div>
  );
}

export default Footer;
