
const serverComodin = {

baseURL: 'https://local.quattropy.com/gsc/server/public/clubcomodin_salas',
//baseURL: 'https://mbokaja.net/server/public/clubcomodin',
//baseURL: 'https://local.quattropy.com/flexible.tools2/server_webv4/public/clubcomodin',
//baseURL: 'https://local.quattropy.com/gsc/server/public/clubcomodin',
    urlProducto:''
}

export default serverComodin;